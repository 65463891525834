import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'

import { extractAge } from '../lib/utils'
import LoadingSpinner from './loading-spinner'
import ClassCard from './class-card'
import Alert from './alert'

const API_ENDPOINT = 'https://jackrabbit-proxy.pritchardpro.vercel.app/'

export default class ClassListings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: null,
      classes: [],
      filteredClasses: [],
      filters: {
        location: false,
        age: false,
        day: false,
        level: false,
      },
    }
  }

  componentDidMount() {
    this.fetchClasses()
  }

  fetchClasses = () => {
    axios
      .get(API_ENDPOINT)
      .then(({ data }) => {
        // filter down to only non-team classes
        const targetRecords = _.filter(data.rows, record => {
          const nonTeamRecord =
            _.toLower(record.category1).indexOf('team') === -1
          return nonTeamRecord
        })
        const sortedListings = this.sortListings(targetRecords)

        this.setState({
          loading: false,
          classes: sortedListings,
          filteredClasses: sortedListings,
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          loading: false,
          error: err.toJSON(),
        })
      })
  }

  sortListings = classes => {
    const classesByLevels = {}
    classes.forEach(record => {
      if (!classesByLevels[record.name]) {
        classesByLevels[record.name] = [record]
      } else {
        classesByLevels[record.name].push(record)
      }
    })

    Object.entries(classesByLevels).forEach(([_level, classSet]) => {
      classSet.sort((classA, classB) => {
        const classADay = Object.values(classA.meeting_days).indexOf(true)
        const classBDay = Object.values(classB.meeting_days).indexOf(true)

        if (classADay === classBDay) {
          const momentA = moment(classA.start_time, 'HH:mm')
          const momentB = moment(classB.start_time, 'HH:mm')
          return momentA.isBefore(momentB) ? -1 : 0
        } else {
          return classADay - classBDay
        }
      })
    })

    return Object.values(classesByLevels).flat()
  }

  renderClasses = () => {
    if (this.state.error) {
      return (
        <Alert
          type="error"
          header="Error Fetching Class Listings"
          body="Please reload this page and try again."
        ></Alert>
      )
    } else if (this.state.loading) {
      return (
        <div className="w-full text-center">
          <LoadingSpinner></LoadingSpinner>
        </div>
      )
    } else if (this.state.filters.location === false) {
      return (
        <Alert
          type="warn"
          header="Location Not Selected"
          body="You must select a location to see class listings."
        ></Alert>
      )
    } else if (this.state.filteredClasses.length === 0) {
      return (
        <Alert
          type="warn"
          header="No Classes Available with Selected Filters"
          body="Please adjust your filter selections to see class listings."
        ></Alert>
      )
    } else {
      return this.state.filteredClasses.map((classObj, index) => (
        <ClassCard key={index} classObj={classObj}></ClassCard>
      ))
    }
  }

  filterClasses = () => {
    const filteredClasses = _.reject(this.state.classes, record => {
      return (
        this.filterLocation(record) ||
        this.filterAge(record) ||
        this.filterDay(record) ||
        this.filterLevel(record)
      )
    })

    this.setState({ filteredClasses })
  }

  filterLocation = record => {
    const locationFilter = this.state.filters.location
    return locationFilter && record.location_code !== locationFilter
  }

  filterAge = record => {
    const ageFilter = this.state.filters.age
    const recordMinAge = extractAge(record.min_age)
    const recordMaxAge = extractAge(record.max_age)

    return ageFilter && (ageFilter < recordMinAge || ageFilter > recordMaxAge)
  }

  filterDay = record => {
    const dayFilter = this.state.filters.day
    return dayFilter && record.meeting_days[dayFilter] !== true
  }

  filterLevel = record => {
    const levelFilter = this.state.filters.level
    return levelFilter === 'Other'
      ? record.category2 !== ''
      : levelFilter && record.category2 !== levelFilter
  }

  filterChange = e => {
    const filters = _.cloneDeep(this.state.filters)
    const filterVal = e.target.value === 'false' ? false : e.target.value

    filters[e.target.id] = filterVal

    this.setState({ filters }, () => {
      this.filterClasses()
    })
  }

  render() {
    return (
      <div>
        <div className="flex flex-wrap bg-grey-light mx-3 md:mx-0 p-4 my-8 border border-grey shadow rounded">
          <div className="w-full md:w-1/2 mx-auto mb-4">
            <div className="flex items-center">
              <div className="w-1/4">
                <label className="block font-bold pr-4" htmlFor="location">
                  Location
                </label>
              </div>
              <div className="w-3/4">
                <div className="inline-block relative w-full md:w-64">
                  <select
                    id="location"
                    className="block appearance-none text-grey-darker w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={this.filterChange}
                    defaultValue="false"
                  >
                    <option value="false" disabled>
                      Select a location
                    </option>
                    <option value="DF">DeForest</option>
                    <option value="CG">Cottage Grove</option>
                  </select>
                  <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 mx-auto mb-4">
            <div className="flex items-center">
              <div className="w-1/4">
                <label className="block font-bold pr-4" htmlFor="age">
                  Age
                </label>
              </div>
              <div className="w-3/4">
                <div className="inline-block relative w-full md:w-64">
                  <select
                    id="age"
                    className="block appearance-none text-grey-darker w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={this.filterChange}
                  >
                    <option value="false">All</option>
                    <option value="1">1 year</option>
                    <option value="2">2 years</option>
                    <option value="3">3 years</option>
                    <option value="4">4 years</option>
                    <option value="5">5 years</option>
                    <option value="6">6+ years</option>
                  </select>
                  <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <div className="flex items-center">
              <div className="w-1/4">
                <label className="block font-bold pr-4" htmlFor="day">
                  Days
                </label>
              </div>
              <div className="w-3/4">
                <div className="inline-block relative w-full md:w-64">
                  <select
                    id="day"
                    className="block appearance-none text-grey-darker w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={this.filterChange}
                  >
                    <option value="false">All</option>
                    <option value="sun">Sunday</option>
                    <option value="mon">Monday</option>
                    <option value="tue">Tuesday</option>
                    <option value="wed">Wednesday</option>
                    <option value="thu">Thursday</option>
                    <option value="fri">Friday</option>
                    <option value="sat">Saturday</option>
                  </select>
                  <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 mb-0">
            <div className="flex items-center">
              <div className="w-1/4">
                <label className="block font-bold pr-4" htmlFor="level">
                  Level
                </label>
              </div>
              <div className="w-3/4">
                <div className="inline-block relative w-full md:w-64">
                  <select
                    id="level"
                    className="block appearance-none text-grey-darker w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={this.filterChange}
                  >
                    <option value="false">All</option>
                    <option value="Parent/Tot">Parent/Tot (1-3 yrs)</option>
                    <option value="Grasshoppers">Grasshoppers (3 yrs)</option>
                    <option value="Kangaroos">Kangaroos (4-5 yrs)</option>
                    <option value="Level 1">Recreational Level 1</option>
                    <option value="Level 2">Recreational Level 2</option>
                    <option value="Level 3">Recreational Level 3</option>
                    <option value="Level 4">Recreational Level 4</option>
                    <option value="Level 5">Recreational Level 5</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-right text-grey-dark px-3 md:px-0 pb-2">
          Showing{' '}
          {this.state.filters.location === false
            ? 0
            : this.state.filteredClasses.length}{' '}
          of {this.state.classes.length} classes
        </div>
        <div className="overflow-auto">{this.renderClasses()}</div>
      </div>
    )
  }
}
